
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '../../types/manhole.d'
import { ElForm } from 'element-ui/types/form'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private searchInfo = {
    deviceNumber: '',
    deviceStatus: ''
  }

  private loading = false
  private dataList: Array<TableData> = []
  page = 1
  size = 10
  total = 0

  private info = {
    manholeId: '',
    deviceId: '',
    manholeName: ''
  }

  private rules={
    deviceId: [
      { required: true, message: '请选择设备', trigger: 'change' }
    ]
  }

  private devList = []

  private visibleShow = false
  private submitShow = false

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<TableData> }>(this.$apis.manhole.selectManholeInfoPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 绑定
  onBind (row: TableData) {
    this.getDevList(row.projectId)
    this.info.manholeId = row.manholeId || ''
    this.info.manholeName = row.manholeName || ''
    this.visibleShow = true
  }

  // 解除绑定
  onRemoveBind (manholeId: string) {
    this.$confirm('确认解除绑定吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.manhole.updateUnBindManhole, { manholeId })
          .then(() => {
            this.$message.success('解除成功')
            this.onSearch()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getDevList (projectId: string) {
    this.$axios.get(this.$apis.manhole.selectManholeListUnBindlist, {
      projectId
    }).then(res => {
      this.devList = res || []
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const info = {
          manholeId: this.info.manholeId,
          deviceId: this.info.deviceId
        }
        this.$axios.post(this.$apis.manhole.updateBindManhole, info).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.onSearch()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.visibleShow = false
    this.info = {
      manholeId: '',
      deviceId: '',
      manholeName: ''
    }
    this.$nextTick(() => {
      (this.$refs.info as ElForm).clearValidate()
    })
  }

  toHistory (row: TableData) {
    this.$router.push({ name: 'manholeListHistory', params: { projectId: this.projectId }, query: { manholeId: row.manholeId, deviceNumber: row.deviceNumber, manholeName: row.manholeName } })
  }
}
